import Text from "./Text";

const TextF6 = Text.withComponent("h3");

TextF6.defaultProps = {
  fontSize: ["1.125rem", "1.25rem"],
  fontFamily: "TTNormsProBold",
  lineHeight: "1.5",
  color: "#29264C"
};

export default TextF6;
