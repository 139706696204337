import Text from "./Text";

const TextF5 = Text.withComponent("h3");

TextF5.defaultProps = {
  fontSize: ["1.25rem", "1.5rem"],
  fontFamily: "TTNormsProBold",
  lineHeight: "1.5",
  color: "#29264C"
};

export default TextF5;
