import React from "react";
import { Container, Row, Col } from "../components/Grid";
import Box from "../components/Box";

import MultipleChoice from "../components/MultipleChoice";
import Essay from "../components/Essay";
import QuizGroup from "../components/QuizGroup";
import RadioMatrix from "../components/RadioMatrix";

const Exercises = () => (
  <Box py={4}>
    <MultipleChoice quizId="5ae1a0801891850004825b8a" />
    <br />
    <RadioMatrix quizId="5ae6d1662d8ed60004fd15b8" />
    <br />
    <RadioMatrix quizId="5ae1b40f1891850004825bad" />
  </Box>
);

export default Exercises;
