import React from "react";
import styled from "styled-components";
import {
  MULTIPLE_CHOICE,
  CHECKBOX,
  PRIVACY_AGREEMENT,
  ESSAY,
  PEER_REVIEW,
  PEER_REVIEWS_RECEIVED,
  OPEN,
  SCALE,
  RADIO_MATRIX
} from "../../constants/quiztypes";

import MultipleChoice from "../MultipleChoice";
import RadioMatrix from "../RadioMatrix";
import Essay from "../Essay";

import { fetchQuiz, submitQuiz } from "../../services/quiznator";

import { BounceLoader } from "react-spinners";

const mapQuizTypeToComponent = {
  [ESSAY]: Essay,
  [MULTIPLE_CHOICE]: MultipleChoice,
  [PEER_REVIEW]: null,
  [PEER_REVIEWS_RECEIVED]: null,
  [CHECKBOX]: null,
  [PRIVACY_AGREEMENT]: null,
  [OPEN]: null,
  [SCALE]: null,
  [RADIO_MATRIX]: RadioMatrix
};

const QuizGroupWrapper = styled.div`
  border: 3px dotted rebeccapurple;
  padding: 2rem;
  background-color: #4844a3;
`;
const PageIndicator = styled.div`
  color: white;
  font-weight: bold;
`;

export default class QuizGroup extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      quizzes: null
    };
  }

  onQuizComplete() {
    this.setState(prevState => {
      if (prevState.page === this.props.quizzes.length) {
        return prevState;
      }
      return { page: prevState.page + 1 };
    });
  }

  componentDidMount() {
    this.props.quizzes.forEach((quizId, idx) => {
      fetchQuiz(quizId).then(quiz => {
        this.setState({ quizzes: { ...this.state.quizzes, [idx]: quiz } });
      });
    });
  }

  renderQuizComponent() {
    const quiz =
      (this.state.quizzes && this.state.quizzes[this.state.page - 1]) ||
      undefined;

    if (!quiz) {
      return <BounceLoader />;
    } else {
      const Component = mapQuizTypeToComponent[quiz.type];

      return Component ? (
        <Component
          onNextQuiz={this.onQuizComplete.bind(this)}
          page={this.state.page}
          quiz={quiz}
          key={quiz.id}
        />
      ) : (
        "no quiz widget available"
      );
    }
  }

  render() {
    const { quizzes } = this.props;
    const quizId = quizzes[this.state.page - 1];
    return (
      <QuizGroupWrapper>
        <div key={this.state.page}>{this.renderQuizComponent()}</div>
        <PageIndicator>
          {this.state.page} / {this.props.quizzes.length}
        </PageIndicator>
      </QuizGroupWrapper>
    );
  }
}
